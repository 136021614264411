export {
  faPlusSquare as farPlusSquare,
} from '@fortawesome/free-regular-svg-icons'

export {
  faTwitch,
  faMarkdown,
} from '@fortawesome/free-brands-svg-icons'

export {
  faMinusCircle,
  faPen,
  faHashtag,
  faPersonWalkingLuggage,
  faBookOpenReader,
  faFeather,
  faBatteryHalf,
  faPieChart,
  faBarsProgress,
  faGauge,
  faGaugeHigh,
  faSkull,
  faCross,
  faSkullCrossbones,
  faBrain,
  faDice,
  faDesktop,
  faScroll,
  faPlusSquare,
  faPlusCircle,
  faPlus,
  faBorderAll,
  faDiceD6,
  faCube,
  faPeopleGroup,
  faChartPie,
  faChartSimple,
  faDisplay,
  faShield,
  faShieldHeart,
  faGift,
  faGifts,
  faArrowsDownToPeople,
  faUserTag,
  faSliders,
  faScrewdriverWrench,
  faEyeDropper,
  faSun,
  faMoon,
  faRotate,
  faCircleNotch,
  faCircleXmark,
  faTrashCan,
  faArrowsRotate,
  faCircleInfo,
  faGripLinesVertical,
  faStar,
  faStarHalfStroke,
  faStarHalf,
  faShieldHalved,
  faBoxArchive,
  faFish,
  faEnvelope,
  faBan,
  faComputer,
  faRightFromBracket,
  faBars,
  faDiceSix,
  faDiceFive,
  faDiceFour,
  faDiceThree,
  faDiceTwo,
  faDiceOne,
  faDiceD20,
  faLock,
  faArrowUp,
  faCaretUp,
  faPencil,
  faHatWizard,
  faCalendar,
  faCalendarDay,
  faEllipsisVertical,
  faLayerGroup,
  faArrowUpRightFromSquare,
  faUnlock,
  faTrash,
  faDumpster,
  faDumpsterFire,
  faSpinner,
  faEye,
  faEyeSlash,
  faLink,
  faCirclePlay,
  faCirclePause,
  faCircleStop,
  faPause,
  faBullhorn,
  faBellConcierge,
  faFloppyDisk,
  faAsterisk,
  faCrown,
  faPeopleArrows,
  faScaleBalanced,
  faScaleUnbalanced,
  faScaleUnbalancedFlip,
  faUser,
  faUsers,
  faUserLarge,
  fa0, fa1, fa2, fa3, fa4, fa5, fa6, fa7, fa8, fa9,
  faMars,
  faVenus,
  faPeoplePulling,
  faUserTie,
  faUserSecret,
  faTag,
  faTags,
  faRotateLeft,
  faTimes,
  faCat,
  faCancel,
  faWindowMinimize,
  faWindowMaximize,
  faLessThanEqual,
  faGreaterThanEqual,
  faMugHot,
  faHandHoldingHeart,
  faHeart,
  faHome,
  faCog,
  faCodeCompare,
  faThumbTack,
  faSearch,
  faFaceDizzy,
} from '@fortawesome/free-solid-svg-icons'

